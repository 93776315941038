<!-- 创建公司 -->
<template>
  <div class="container">
    <div class="body">
      <span class="line"></span>
      <div class="tit">创建公司</div>
      <el-form ref="form" :model="form" :rules="formRules" label-width="100px">
        <el-form-item label="用户名称" prop="createBy">
          <el-input
            v-model="form.createBy"
            placeholder="请输入用户名"
            class="common-screen-input_100"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <span>{{ form.createId }}</span>
        </el-form-item>
        <el-form-item label="公司名称" prop="name">
          <el-input
            v-model="form.name"
            placeholder="请输入公司名"
            class="common-screen-input_100"
          ></el-input>
        </el-form-item>
        <el-form-item label="行业类型">
          <el-select
            v-model="form.scale"
            class="common-screen-input_100"
            placeholder="请选择"
          >
            <el-option label="IT/通信/电子/互联网" :value="0"></el-option>
            <el-option label="金融业" :value="1"></el-option>
            <el-option label="商业服务" :value="2"></el-option>
            <el-option label="贸易/批发/零售/租赁业" :value="3"></el-option>
            <el-option label="文体教育/工艺美术" :value="4"></el-option>
            <el-option label="服务业" :value="5"></el-option>
            <el-option label="文化/传媒/娱乐/体育" :value="6"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="人员规模">
          <el-select
            v-model="form.industry"
            class="common-screen-input_100"
            placeholder="请选择"
          >
            <el-option label="0-50人" :value="0"></el-option>
            <el-option label="50-100人" :value="1"></el-option>
            <el-option label="100-500人" :value="2"></el-option>
            <el-option label="500-1000人" :value="3"></el-option>
            <el-option label="1000人以上" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="公司地址">
          <el-cascader
            :props="props"
            v-model="form.address_id"
            class="add-ipt"
            clearable
            placeholder="省市区"
          ></el-cascader>
          <el-input
            v-model="form.address"
            class="common-screen-input_55"
            placeholder="请输入具体地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="运营执照">
          <div v-if="!fileList.length" class="uploadBtn-container">
            <i class="iconfont icon-ze-add"></i>
            <span class="up">点击上传营业执照</span>
            <input
              @input="
                (e) => {
                  uploadFile(e);
                }
              "
              class="uploadBtn-input"
              type="file"
            />
          </div>
          <div v-else class="fileList-container">
            <div
              v-for="(item, index) in fileList"
              :key="index"
              class="fileList-item"
            >
              <div class="item-name">
                <common-picture
                  :fileUrl="item.fileUrl"
                  :fileType="
                    Config.fileType.imgType.includes(
                      item.fileUrl.split('.')[
                        item.fileUrl.split('.').length - 1
                      ]
                    )
                      ? 'img'
                      : 'file'
                  "
                  :fileName="item.fileName"
                ></common-picture>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item class="com">
          <el-button @click="submitForm()" class="createCom"
            >立即创建公司</el-button
          >
        </el-form-item>
      </el-form>
      <!-- <div><el-radio v-model="radio" label="1">接受《帮客智能客服管理系统公司协议》</el-radio></div> -->
      <div class="foot">有问题联系管理员</div>
    </div>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import { Config } from "../../utils/index.js";
import { sysArea } from "../../service/common.js";
import { upload } from "../../service/upload.js";
import { found } from "../../service/login.js";
import CommonPicture from "../../components/common/commonPicture.vue";

export default {
  name: "createCom",
  components: { CommonPicture },
  props: {},
  data() {
    return {
      Config,
      form: {
        createBy: "", //用户昵称
        createId: this.$route.query.phone, //手机号
        name: "", //公司名称
        scale: "", //公司类型
        industry: "", //公司规模
        address_id: "", //公司所属地区ID
        address: "", //公司地址
        fileName: "",
        filedId: "",
        filedUrl: "",
        status: "1"
      },
      formRules: {
        createBy: [
          { required: true, message: "请输入用户名称", trigger: "blur" }
        ],
        name: [{ required: true, message: "请输入公司名称", trigger: "blur" }]
      },
      props: {
        //地区
        lazy: true,
        lazyLoad: async (node, resolve) => {
          const { level, value } = node;
          let typeId;
          if (level == 0) {
            typeId = 0;
          } else {
            typeId = value;
          }
          let resData = (await sysArea({ id: typeId })).data;
          let nodes = resData.map((item) => ({
            value: item.id + "",
            label: item.name,
            leaf: level >= 2
          }));
          resolve(nodes);
        }
      },
      loading2: false, //上传
      fileList: [] //图片
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //方法集合
  methods: {
    async uploadFile(e) {
      // 上传
      this.loading2 = true;
      let file = e.target.files[0];
      let fileName = file.name;
      this.form.fileName = fileName;
      if (file.size / 1024 > 1024 * 50) {
        this.loading2 = false;
        e.target.value = "";
        return this.$message.error("文件大小不能超过50M");
      }
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (!Config.fileType.PWEIType.includes(format)) {
        e.target.value = "";
        this.loading2 = false;
        return this.$message.error(
          `只能上传 ${Config.fileMessage.PWEIType} 格式的文件`
        );
      }
      let resData = (await upload({ file: file })).data;
      console.log(resData, "上传图片");
      this.fileList.push({ fileName: fileName, fileUrl: resData.filename });
      this.form.fileId = resData.id;
      this.form.fileUrl = resData.filename;
      e.target.value = "";
    },
    submitForm() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          let params = { ...this.form };
          params.addressId = params.address_id.join(",");
          await found(params);
          this.$router.push({ path: "/index" });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  box-sizing: border-box;
  padding: 96px 291px;
  width: 100%;
  // height: 100vh;
  background-size: 100% 100%;
  background-color: #ebf0ff;
  background-image: url("../../assets/images/login_background.png");
  background-repeat: no-repeat;
  .body {
    box-sizing: border-box;
    padding: 80px;
    width: 100%;
    // height: 100%;
    background: #fff;
    .line {
      display: block;
      width: 40px;
      height: 6px;
      margin-bottom: 8px;
      background: #1890ff;
      border-radius: 8px;
    }
    .tit {
      margin-bottom: 80px;
      color: rgba(16, 16, 16, 100);
      font-size: 32px;
      text-align: left;
    }
    /deep/ .el-form {
      width: 600px;
      margin: 0 auto;
      .el-form-item__label {
        font-size: 16px;
        text-align: center;
      }
      .el-form-item__content {
        text-align: left;
        .createCom {
          width: 500px;
          height: 48px;
          line-height: 24px;
          background-color: #1890ff !important;
          color: #fff;
          span {
            font-size: 24px;
          }
        }
      }
      .com {
        margin-top: 80px;
      }
    }
    /deep/ .common-screen-input_55 {
      margin-left: 8px;
    }
    /deep/ .el-select {
      width: 216px;
    }
    /deep/ .el-input__inner {
      height: 48px;
    }
    .tip {
      font-size: 12px;
    }
    .upload-title {
      font-size: 18px;
      color: #101010;
    }
    .uploadBtn-container {
      position: relative;
      z-index: 2;
      width: 500px;
      height: 240px;
      line-height: 240px;
      border: 1px solid rgba(220, 223, 230, 100);
      color: #1890ff;
      font-size: 16px;
      background: #fff;
      background: url("~@/assets/images/business.png") no-repeat center;
      text-align: center;
      border-radius: 4px;
      display: inline-block;
      position: relative;
      .up {
        display: inline-block;
        margin-top: 30px;
      }
      .iconfont {
        position: absolute;
        top: -9%;
        left: 50%;
        transform: translate(-50%);
        font-size: 64px;
        color: #1890ff;
      }
      .uploadBtn-input {
        width: 500px;
        height: 240px;
        cursor: pointer;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
      }
    }
  }
  .foot {
    color: rgba(153, 153, 153, 100);
    font-size: 16px;
    padding-left: 100px;
  }
  .fileList-container {
    width: 500px;
    height: 240px;
    .fileList-item {
      display: flex;
      justify-content: space-between;
      width: 320px;
      .item-name {
        font-size: 12px;
        color: #333;
        .commonPicture-container {
          /deep/ .fileImg {
            width: 500px;
            height: 240px;
            margin-top: 0;
            .el-image__inner {
              // width: auto;
              width: 500px;
              height: 240px;
            }
          }
        }
      }
      .deleteBtn {
        color: #1890ff;
        cursor: pointer;
      }
    }
  }
}
</style>
